<template>
  <div class="architecture">
    <div class="architecture-type">
      <span class="architecture-type_label">{{ $t("casbee.label_input_format") }}</span>
      <div class="architecture-type_radio">
        <v-radio-group v-model="inputType" inline class="casbee-radio" :disabled="isDetailData">
          <div class="radio-part">
            <v-radio v-for="itemType in itemsTypeInput" :key="itemType" :label="`${itemType}`" :value="itemType" :ripple="false"></v-radio>
        </div>
        </v-radio-group>
      </div>
    </div>
    <div class="architecture-type mt-12">
      <span class="architecture-type_label">{{ $t("casbee.label_building_use") }}</span>
      <div class="architecture-type_radio">
        <v-radio-group v-model="typePerposeUsing" inline class="casbee-radio" :disabled="isDetailData">
          <div class="radio-part">
            <v-radio
              v-for="itemType in itemsTypePurposeUsing"
              :key="itemType"
              :label="`${itemType}`"
              :value="itemType"
              :ripple="false"
            ></v-radio>
          </div>
          <div class="perpose-using" v-if="typePerposeUsing === useAsOutside">
            <default-pull-down-menu
              v-model="perposeUsingBuildingModel"
              :items="perposeUsingBuildingList"
              :initialValue="perposeUsingBuildingModel"
              placeHolderText="計算式を利用して原単位を追加"
              :width="pullDownWidth"
              :height="'32px'"
              :isCasbeePulldown="true"
              :disabled="isViewMode"
              class="casbee-pulldown"
            />
          </div>
        </v-radio-group>
      </div>
    </div>
    <component
      v-if="inputType !== null && typePerposeUsing !== null"
      :is="optionComponent"
      :detail-data="detailData"
      :perposeUsingBuildingModel="perposeUsingBuildingModel"
      @onHandlerCalculationData="onHandlerCalculationData"
    />

  </div>
</template>
<script>
import { INPUT_TYPE, PURPOSE_USING, CASBEE_TYPE } from '@/constants/casbee';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import { PERPOSE_USING_BUILDING } from '@/constants/casbee/outside';
import {getWidthByTextContent} from '@/utils/calcTextWidth'

export default {
  components: {
    DefaultPullDownMenu
  },
  props: {
    detailData: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      inputType: INPUT_TYPE.CHOOSE_CONDITION,
      typePerposeUsing: PURPOSE_USING.APARTMENT,
      itemsTypeInput: Object.values(INPUT_TYPE),
      itemsTypePurposeUsing: Object.values(PURPOSE_USING),
      perposeUsingBuildingModel: PERPOSE_USING_BUILDING.OFFICE,
      useAsOutside: PURPOSE_USING.OUTSIDE,
      perposeUsingBuildingList: Object.keys(PERPOSE_USING_BUILDING).map((item) => {
        return { name: PERPOSE_USING_BUILDING[item], value: PERPOSE_USING_BUILDING[item]};
      }),
      pullDownWidth: '200px'
    }
  },
  mounted() {
    this.prepareDetailData();
    this.pullDownWidth = this.getWidth();
    this.onWindowResize();
  },
  methods: {
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.pullDownWidth = this.getWidth();
      }, 100);
    },
    getWidth() {
      if (window.innerWidth >= 1024) {
        return this.widthPulldownByText(this.perposeUsingBuildingList, '200px');
      } else if (window.innerWidth >= 375) {
        return '100%';
      }
      return '100%';
    },
    widthPulldownByText(dataList, widthJa) {
      if(this.$i18n.locale === 'ja') {
        return widthJa;
      }
      let widthListPulldowns = [];
      dataList.forEach(item => {
        widthListPulldowns.push(getWidthByTextContent(item.name));
      });
      return Math.max(...widthListPulldowns) + 'px';
    },
    onHandlerCalculationData(data) {
      this.$emit('onHandlerCalculationData', data);
    },
    prepareDetailData() {
      if (!this.detailData.data) return;
      if(this.detailData.hasOwnProperty('dataDetail')) {
        this.perposeUsingBuildingModel = this.detailData.dataDetail.content_json?.perposeUsingBuildingModel;
      }
      switch (this.detailData?.data[0]?.pattern_type) {
        case CASBEE_TYPE.ARCHITECTURE_APARTMENT_CHOOSE_CONDITION:
          this.typePerposeUsing = PURPOSE_USING.APARTMENT;
          this.inputType = INPUT_TYPE.CHOOSE_CONDITION;
          break;
        case CASBEE_TYPE.ARCHITECTURE_APARTMENT_ENTER_FREELY:
          this.typePerposeUsing = PURPOSE_USING.APARTMENT;
          this.inputType = INPUT_TYPE.ENTER_FREELY;
          break;
        case CASBEE_TYPE.ARCHITECTURE_OUTSIDE_CHOOSE_CONDITION:
          this.typePerposeUsing = PURPOSE_USING.OUTSIDE;
          this.inputType = INPUT_TYPE.CHOOSE_CONDITION;
          break;
        case CASBEE_TYPE.ARCHITECTURE_OUTSIDE_ENTER_FREELY:
          this.typePerposeUsing = PURPOSE_USING.OUTSIDE;
          this.inputType = INPUT_TYPE.ENTER_FREELY;
          break;
        default:
          break;
      }
    },
  },
  computed: {
    isViewMode() {
      return this.detailData.hasOwnProperty('dataDetail');
    },
    optionComponent() {
      let page = null;
      if (this.inputType === INPUT_TYPE.CHOOSE_CONDITION) {
        if (this.typePerposeUsing === PURPOSE_USING.APARTMENT) {
          page = 'apartment/choose-condition'
        }
        if (this.typePerposeUsing === PURPOSE_USING.OUTSIDE) {
          page = 'outside/outside-choose-condition'
        }
      }
      if (this.inputType === INPUT_TYPE.ENTER_FREELY) {
        if (this.typePerposeUsing === PURPOSE_USING.APARTMENT) {
          page = 'apartment/enter-freely'
        }
        if (this.typePerposeUsing === PURPOSE_USING.OUTSIDE) {
          page = 'outside/outside-enter-freely'
        }
      }
      this.$emit('onHandlerCalculationData', {});
      return () => import(`@/views/products/emissions/dbCustomize/architecture/${page}.vue`);
    },
    isDetailData() {
      return Object.keys(this.detailData).length > 0 ? true : false;
    }
  },
}
</script>
<style scoped lang="scss">
.architecture {
  &-type {
    &_label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
    }
    &_radio {
      margin-top: 8px;
    }
    .perpose-using {
      width: 100%;
      margin-top: 16px;
    }
  }
}
@include desktop {
  .architecture {
    &-type {
      &_label {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.05em;
        color: $monoBlack;
      }
      &_radio {
        margin-top: 12px;
      }
      .perpose-using {
        width: 200px;
        margin-top: 12px;
      }
    }
  }
}
</style>